<template>
  <v-textarea
    outlined
    color="vflPrimary"
    v-model="internalMessage"
    no-resize
    @input="handleInput"
    :height="height"
    :counter="showCounter"
    :counter-value="showCounter ? counterValue : null"
    :maxlength="maxlength"
    :label="label"
    :class="{ 'label-is-hidden': hideLabel }"
    :hide-details="!showCounter"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "vfl-textarea",
  props: {
    value: String,
    maxlength: {
      type: Number,
      default: 1000
    },
    height: {
      type: String,
      default: "300"
    },
    showCounter: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalMessage: this.value
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.internalMessage);
    },
    counterValue(value) {
      return `${value.length}/${this.maxlength}`;
    }
  },
  watch: {
    value(newVal) {
      this.internalMessage = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components.scss";

.label-is-hidden {
  ::v-deep {
    label {
      opacity: 0;
    }

    legend {
      display: none;
    }
  }
}

::v-deep .v-text-field__slot textarea {
  @include scrollbar("vertical");
  margin-right: 2px !important;
  margin-bottom: 2px !important;
}
</style>

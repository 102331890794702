<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.balance.title")
    }}</template>
    <template #content>
      <div class="vfl-card-light pa-8 pb-12 align-start d-md-flex">
        <img
          :src="pregnancyReportBalanceService.getIllustrationPath()"
          alt=""
        />
        <div class="content">
          <p
            v-for="(p, index) in pregnancyReportBalanceService.getText()"
            :key="`balance-paragraph-${index}`"
            v-html="p"
          ></p>
          <did-not-know-that-fact-button
            class="mt-8"
            :fact="$t(`pregnancyAssessment.report.content.balance.title`)"
            :assessmentType="$options.assessmentTypes.pregnancyAssessment"
          />
        </div>
      </div>
      <recommendation-container
        class="mt-10"
        :title="
          $t('pregnancyAssessment.report.content.balance.recommendationsTitle')
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="balanceRecommendations"
        :default-tab="defaultRecommendationTab"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { PregnancyReportBalanceService } from "@/components/assessment/pregnancy/report/pregnancy-report-balance-service.js";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections,
  getDefaultTab
} from "@/components/assessment/pregnancy/report/recommendations/recommendation-service.js";

export default {
  name: "PregnancyReportBalance",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer
  },
  data() {
    return {
      pregnancyReportBalanceService: null,
      balanceRecommendations: {},
      defaultRecommendationTab: 0
    };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.pregnancyReportBalanceService = new PregnancyReportBalanceService(
      this.results
    );

    this.balanceRecommendations = getRecommendationsByType(
      RecommendationSections.BALANCE,
      this.results
    );

    this.defaultRecommendationTab = getDefaultTab(
      RecommendationSections.BALANCE,
      this.results
    );
  }
};
</script>

<style scoped lang="scss">
.vfl-card-light {
  background: white;
  gap: 1rem;
}
</style>

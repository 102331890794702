<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-3">
      <p class="font-weight-bold mb-0">
        {{ $t("invite.dialog.message.title") }}
      </p>
      <div v-if="showButtons" class="d-flex" style="gap: 1rem">
        <button
          class="vfl-button-link text-small font-medium"
          @click="resetMessage"
          :class="{ active: enableReset }"
          data-testid="reset-button"
          style="text-decoration: none"
        >
          {{ $t("invite.dialog.message.button.reset") }}
        </button>
        <button
          class="vfl-button-link text-small"
          @click="createOrUpdateMessage"
          :class="{ active: enableSave }"
          style="text-decoration: none"
        >
          {{ $t("invite.dialog.message.button.create") }}
        </button>
      </div>
    </div>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      max-width="100%"
      height="330"
      min-height="300"
    />
    <vfl-textarea
      v-else
      :value="message"
      @input="onInput"
      :disabled="disableTextArea"
      :maxlength="1600"
    />

    <v-snackbar
      v-model="showMessageTemplateSnackbar"
      timeout="2500"
      :color="messageTemplateSnackbarColor"
      :right="true"
    >
      {{ messageTemplateSnackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import VflTextarea from "@/components/vitrue-foundation-library/form/VflTextarea.vue";
import {
  fetchMessageTemplates,
  updateMessageTemplate,
  createMessageTemplate,
  deleteInviteTemplate
} from "@/services/dashboard/message-template-api-service";
import {
  ExternalNotificationProviders,
  assessmentLocalePaths
} from "@/constants/constants";
import { mapGetters } from "vuex";

const SnackbarColor = {
  SUCCESS: "#0D865A",
  ERROR: "#CA1010"
};

export default {
  name: "CustomMessageComponent",
  components: { VflTextarea },
  props: {
    teamName: String,
    assessmentType: {
      type: String,
      required: true
    },
    externalNotificationProvider: Number
  },
  data() {
    return {
      loading: false,
      defaultMessage: "",
      defaultExternalProviderMessage: "",
      message: "",
      fetchedMessage: "",
      externalNotificationMessage: "",
      showMessageTemplateSnackbar: false,
      messageTemplateSnackbarText: "",
      messageTemplateSnackbarColor: SnackbarColor.SUCCESS,
      customTemplate: null,
      previousEmailMessage: null
    };
  },
  computed: {
    ...mapGetters(["userName"]),
    changed() {
      return this.message !== this.fetchedMessage;
    },
    showButtons() {
      return (this.changed || !!this.customTemplate) && !this.disableTextArea;
    },
    enableReset() {
      return !this.loading && (!!this.customTemplate || this.changed);
    },
    enableSave() {
      return this.changed && !this.loading && !this.usingDefault;
    },
    usingDefault() {
      return this.message === this.defaultMessage;
    },
    disableTextArea() {
      return (
        this.externalNotificationProvider != ExternalNotificationProviders.EMAIL
      );
    }
  },
  created() {
    this.setDefaultMessage();
    this.loadMessage();
  },
  methods: {
    setDefaultMessage() {
      const team = this.teamName
        ? this.teamName
        : this.$t("invite.dialog.yourCompany");

      this.defaultMessage = this.$t(
        `invite.dialog.defaultMessages.${
          assessmentLocalePaths[this.assessmentType]
        }`,
        { teamName: team }
      );

      const externalName = this.userName ?? team;
      this.defaultExternalProviderMessage = this.$t(
        "invite.dialog.defaultMessages.externalDesk",
        { name: externalName }
      );
    },
    async loadMessage() {
      this.loading = true;

      const { data } = await fetchMessageTemplates(this.assessmentType);

      if (data) {
        this.customTemplate = data;
      }

      const loadedMessage = this.customTemplate?.content || this.defaultMessage;
      if (
        this.externalNotificationProvider ===
        ExternalNotificationProviders.EMAIL
      ) {
        this.message = loadedMessage;
      } else {
        this.message = this.defaultExternalProviderMessage;
      }

      this.fetchedMessage = loadedMessage;
      this.loading = false;

      this.$emit("message-updated", {
        message: this.message,
        default: this.usingDefault
      });
    },
    onInput(value) {
      this.message = value;
      this.$emit("message-updated", {
        message: value,
        default: this.usingDefault
      });
    },
    createOrUpdateMessage() {
      if (!this.enableSave) return;

      if (this.customTemplate?.id) {
        this.updateMessage();
      } else {
        this.createMessage();
      }
    },
    async updateMessage() {
      const { data, error } = await updateMessageTemplate(
        this.customTemplate.id,
        this.assessmentType,
        this.message
      );

      if (data) {
        this.fetchedMessage = this.message;

        this.onSuccess(
          this.$t("invite.dialog.message.snackbar.create.success")
        );
      }

      if (error) {
        this.onError(this.$t("invite.dialog.message.snackbar.create.error"));
      }
    },
    async createMessage() {
      const { data, error } = await createMessageTemplate(
        this.assessmentType,
        this.message
      );

      if (data) {
        this.customTemplate = {
          id: data,
          type: this.assessmentType,
          content: this.message
        };
        this.fetchedMessage = this.message;

        this.onSuccess(
          this.$t("invite.dialog.message.snackbar.create.success")
        );
      }

      if (error) {
        this.onError(this.$t("invite.dialog.message.snackbar.create.error"));
      }
    },
    async resetMessage() {
      if (!this.enableReset) return;

      if (this.customTemplate) {
        const { data, error } = await deleteInviteTemplate(
          this.customTemplate.id
        );
        if (data) {
          this.onSuccess(
            this.$t("invite.dialog.message.snackbar.create.success")
          );
        }
        if (error) {
          this.onError(this.$t("invite.dialog.message.snackbar.reset.error"));
        }
      }

      this.customTemplate = null;
      this.message = this.defaultMessage;
      this.fetchedMessage = this.message;

      this.$emit("message-updated", {
        message: this.message,
        default: this.usingDefault
      });
    },
    onSuccess(text) {
      this.messageTemplateSnackbarColor = SnackbarColor.SUCCESS;
      this.showMessageTemplateSnackbar = true;
      this.messageTemplateSnackbarText = text;
    },
    onError(text) {
      this.messageTemplateSnackbarColor = SnackbarColor.ERROR;
      this.showMessageTemplateSnackbar = true;
      this.messageTemplateSnackbarText = text;
    },
    setMessageForProvider(newProvider) {
      if (newProvider === ExternalNotificationProviders.EMAIL) {
        this.message =
          this.previousEmailMessage ||
          this.fetchedMessage ||
          this.defaultMessage;
      } else {
        if (
          this.externalNotificationProvider ===
          ExternalNotificationProviders.EMAIL
        ) {
          this.previousEmailMessage = this.message;
        }
        this.message = this.defaultExternalProviderMessage;
      }

      this.$emit("message-updated", {
        message: this.message,
        default: this.usingDefault
      });
    }
  },
  watch: {
    externalNotificationProvider: {
      immediate: true,
      handler(newProvider) {
        this.setMessageForProvider(newProvider);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  color: #626e7c40;
  pointer-events: none;

  &.active {
    color: var(--v-vflPrimary-base);
    pointer-events: auto;
  }
}

::v-deep .v-skeleton-loader__image {
  height: 100%;
}
</style>

import store from "@/store";
import { assessmentTypes } from "@/constants/constants.js";
import { handleAssessmentRedirect } from "@/services/authorization/url-redirect/assessment-redirect-handler.js";
import { handleWellnessRedirect } from "@/services/authorization/url-redirect/wellness-redirect-handler.js";
import { handlePeopleRedirect } from "@/services/authorization/url-redirect/people-redirect-handler.js";
import { handleMsTeamsRedirect } from "@/services/authorization/url-redirect/ms-teams-redirect-handler.js";
import {
  RedirectPages,
  RedirectParameters,
  RedirectStoreRoutes
} from "@/services/authorization/url-redirect/redirect-constants.js";
import { routes } from "@/router/route-constants.js";
import { removeSpecificUrlParameters } from "@/services/url-service.js";
import sentry from "@/sentry.js";
import { isGuidValid } from "@/utils/auth-validation.js";

const redirectHandlers = {
  [RedirectPages.DESK_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.deskAssessment),
  [RedirectPages.WORKSPACE_RESILIENCE_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.burnoutAssessment),
  [RedirectPages.DRIVER_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.driverAssessment),
  [RedirectPages.PHYSICAL_LABOUR_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.physicalLabourAssessment),
  [RedirectPages.MENOPAUSE_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.menopauseAssessment),
  [RedirectPages.MENSTRUATION_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.menstruationAssessment),
  [RedirectPages.PREGNANCY_ASSESSMENT]: () =>
    handleAssessmentRedirect(assessmentTypes.pregnancyAssessment),
  [RedirectPages.WELLNESS]: () => handleWellnessRedirect(),
  [RedirectPages.ANALYTICS]: () => ({ name: routes.ANALYTICS }),
  [RedirectPages.PEOPLE]: () => handlePeopleRedirect(),
  [RedirectPages.MS_TEAMS_DASHBOARD]: () => handleMsTeamsRedirect()
};

export async function handleUrlRedirect() {
  let destination = null;
  try {
    const redirectPage =
      store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_PAGE];

    if (redirectPage?.startsWith(RedirectPages.USER_PROFILE)) {
      destination = handleUserProfileRedirect(redirectPage);
    } else if (redirectHandlers[redirectPage]) {
      destination = await redirectHandlers[redirectPage]();
    } else {
      throw new Error(
        `${redirectPage} page is not handled in handleUrlRedirect`
      );
    }
  } catch (err) {
    sentry.captureException(err);
  } finally {
    resetUrlRedirect();
    return destination;
  }
}

function handleUserProfileRedirect(redirectPage) {
  const pageArray = redirectPage.split("/");
  const validArray = pageArray.length >= 2;
  const guid = validArray ? pageArray[1] : null;
  const params = store.getters[RedirectStoreRoutes.GETTERS.REDIRECT_PARAMS];

  return guid && isGuidValid(guid)
    ? {
        path: `/people/${guid}`,
        props: {
          userId: guid,
          ...params
        }
      }
    : { name: routes.PEOPLE };
}

function resetUrlRedirect() {
  const parameters = Object.values(RedirectParameters);
  removeSpecificUrlParameters(window.location.href, parameters);
  store.commit(RedirectStoreRoutes.MUTATIONS.SET_REDIRECT_DATA, null);
}
